import Vue from 'vue';
import Router from 'vue-router';
import storage from './sdk/common/Storage';
import Auth from './sdk/common/Auth';
import lang from './lang';
// import Home from './views/Home.vue';
// let userAgent:string = '';
// if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('Android ') !== -1) {
//   userAgent = 'mobile';
// } else {
//   userAgent = 'pc';
// }
Vue.use(Router);

export const pRoutes = [
  {
    path: '/building',
    name: 'building',
    component: () => import('./components/service/InsBuilding.vue'),
    children: []
  },
  {
    path: '/',
    name: 'main',
    component: () => import('./platform/pc/index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('./views/pc/InsHome.vue')
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsAbout.vue'
                 )
      },
      {
        path: '/header',
        name: 'header',
        component: () =>
                 import('./components/business/pc/header/InsHeader.vue')
      },
      {
        path: '/product/detail/:id',
        name: 'ProductsDetail',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsProductDetail.vue'
                 )
      },
      {
        path: '/product/search/:key',
        name: 'productSearch',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsProductSearch.vue'
                 ),
        props: route => ({
          attrs: route.query.attrs,
          catalogs: route.query.catalogs,
          type: route.query.type
        })
      },
      {
        path: '/product/cat/:catId?',
        name: 'product',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsProductCat.vue'
                 )
      },
      {
        path: '/contact',
        name: 'contact',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsContact.vue'
                 )
      },
      {
        path: '/service',
        name: 'service',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsService.vue'
                 )
      },
      {
        path: '/award',
        name: 'award',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsAward.vue'
                 )
      },
      {
        path: '/news',
        name: 'news',
        redirect: 'news/newsmain/',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsNews.vue'
                 ),
        // 建立子路由
        children: [
          {
            path: 'newsmain/',
            name: 'newsmain',
            component: () =>
                     import(
                       /* webpackChunkName: "about" */ './components/business/pc/news/InsNewsMain.vue'
                     )
          }
        ]
      },
      {
        path: '/cms/content/:id',
        name: 'cmsContent',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/cms/InsCmsContent.vue'
                 )
      },
      {
        path: '/cms/news/:id',
        name: 'cmsNews',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/cms/InsCmsNews.vue'
                 )
      },
      {
        path: '/cms/aricle/:id',
        name: 'cmsAricle',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/cms/InsCmsAricle.vue'
                 )
      },
      {
        path: '/cms/login/:id',
        name: 'cmsLogin',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/cms/InsCmsLogin.vue'
                 )
      },
      {
        path: '/cms/catDetail/:id',
        name: 'catDetail',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/cms/InsCatDetail.vue'
                 )
      },
      // 会员中心路由开始
      {
        path: '/memberCentra/:location?',
        name: 'memberCentra',
        component: () =>
                 import('@/components/business/pc/account/InsMemberCentra.vue')
      },
      {
        path: '/account/login',
        name: 'login',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsLogin.vue'
                 )
      },
      {
        path: '/account/register',
        name: 'register',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsRegister.vue'
                 )
      },
      {
        path: '/account/registerSuccess',
        name: 'registerSuccess',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsRegisterSuccess.vue'
                 )
      },
      {
        path: '/account/memberInfo',
        name: 'memberInfo',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsMemberInfo.vue'
                 )
      },
      {
        path: '/account/myFavorite',
        name: 'myFavorite',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsMyFavorite.vue'
                 )
      },
      {
        path: '/account/deliveryAddress',
        name: 'deliveryAddress',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsDeliveryAddress.vue'
                 )
      },
      {
        path: '/account/myCoupon',
        name: 'myCoupon',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsMyCoupon.vue'
                 )
      },
      {
        path: '/account/notification',
        name: 'notification',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsNotification.vue'
                 )
      },
      {
        path: '/account/shoppingcart',
        name: 'shoppingcart',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsShoppingCart.vue'
                 )
      },
      {
        path: '/account/forgetPassword',
        name: 'forgetPassword',
        // meta: {
        //   requiresAuth: true // 是否进行登录验证
        // },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsForgetPassword.vue'
                 )
      },
      {
        path: '/account/checkout',
        name: 'checkout',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/shoppingcart'
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsCheckout.vue'
                 )
      },
      {
        path: '/account/points',
        name: 'points',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsPoints.vue'
                 )
      },

      {
        path: '/account/orderList',
        name: 'OrderList',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsOrderList.vue'
                 )
      },
      {
        path: '/account/orderDetail/:id',
        name: 'orderDetail',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsOrderDetail.vue'
                 )
      },
      {
        path: '/account/orderComment/:id',
        name: 'orderComment',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsAddComments.vue'
                 )
      },
      {
        path: '/account/orderComplete/:id',
        name: 'OrderComplete',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsOrderComplete.vue'
                 )
      },
      {
        path: '/account/completeCheckout/:id',
        name: 'completeCheckout',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/account/InsCompleteCheckout.vue'
                 )
      },
      // 付款状态
      {
        path: '/payment/paySuccess',
        name: 'paySuccess',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/payment/InsPaySuccess.vue'
                 )
      },
      {
        path: '/payment/payFail',
        name: 'payFail',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './components/business/pc/payment/InsPayFail.vue'
                 )
      },
      {
        path: '/regnpay/form/:id',
        name: 'regnpay',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsRegAndPay.vue'
                 )
      },
      {
        path: '/regnpay/result/:id',
        name: 'regnpayResult',
        component: () =>
                 import(
                   /* webpackChunkName: "about" */ './views/pc/InsRegNPayResult.vue'
                 )
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/pc/InsAdmin.vue')
      }
    ]
  }
];

export const mRoutes = [
  {
    path: '/building',
    name: 'building',
    component: () => import('./components/service/InsBuilding.vue'),
    children: []
  },
  {
    path: '/',
    name: 'main',
    component: () => import('./platform/mobile/index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('./views/mobile/InsHome.vue')
      },
      {
        path: '/product/detail/:id',
        name: 'ProductsDetail',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './views/mobile/InsProductDetail.vue'
          )
      },
      {
        path: '/product/search/:key',
        name: 'productSearch',
        component: () =>
          import(/* webpackChunkName: "about" */ './views/mobile/InsProductSearch.vue')
      },
      {
        path: '/product/cat/:catId?',
        name: 'productCat',
        component: () =>
          import(/* webpackChunkName: "about" */ './views/mobile/InsProductCat.vue')
      },
      // 会员中心路由开始
      {
        path: '/account/login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsLogin.vue'
          )
      },
      {
        path: '/account/register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsRegister.vue'
          )
      },
      {
        path: '/account/registerSuccess',
        name: 'registerSuccess',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsRegisterSuccess.vue'
          )
      },
      {
        path: '/account/memberInfo',
        name: 'memberInfo',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsMemberInfo.vue'
          )
      },
      {
        path: '/account/myFavorite',
        name: 'myFavorite',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsMyFavorite.vue'
          )
      },
      {
        path: '/account/deliveryAddress',
        name: 'deliveryAddress',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsDeliveryAddress.vue'
          )
      },
      {
        path: '/account/myCoupon',
        name: 'myCoupon',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsMyCoupon.vue'
          )
      },
      {
        path: '/account/notification',
        name: 'notification',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsNotification.vue'
          )
      },
      {
        path: '/account/forgetPassword',
        name: 'forgetPassword',
        // meta: {
        //   requiresAuth: true // 是否进行登录验证
        // },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsForgetPassword.vue'
          )
      },
      {
        path: '/account/points',
        name: 'points',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsPoints.vue'
          )
      },

      {
        path: '/account/orderList',
        name: 'OrderList',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderList.vue'
          )
      },
      {
        path: '/account/orderDetail/:id',
        name: 'orderDetail',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderDetail.vue'
          )
      },
      {
        path: '/account/orderComplete/:id',
        name: 'OrderComplete',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsOrderComplete.vue'
          )
      },
      {
        path: '/account/orderComment/:id',
        name: 'orderComment',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsAddComments.vue'
          )
      },
      {
        path: '/account/completeCheckout/:id',
        name: 'completeCheckout',
        meta: {
          requiresAuth: true // 是否进行登录验证
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsCompleteCheckout.vue'
          )
      },
      {
        path: '/account/shoppingcart',
        name: 'shoppingcart',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './views/mobile/InsShoppingcart.vue'
          )
      },
      {
        path: '/account/checkout',
        name: 'checkout',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/shoppingcart'
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './views/mobile/InsCheckout.vue'
          )
      },
      {
        path: '/account/complete/:id',
        name: 'complete',
        meta: {
          requiresAuth: true, // 是否进行登录验证
          onlyFrom: '/account/checkout'
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/account/InsCompleteCheckout.vue'
          )
      },
      {
        path: '/news',
        name: 'news',
        redirect: 'news/newsmain/',
        component: () =>
          import(/* webpackChunkName: "about" */ './views/mobile/InsNews.vue'),
        // 建立子路由
        children: [
          {
            path: 'newsmain/',
            name: 'newsmain',
            component: () =>
              import(
                /* webpackChunkName: "about" */ './components/business/mobile/news/InsNewsMain.vue'
              )
          }
        ]
      },
      {
        path: '/cms/content/:id',
        name: 'cmsContent',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/cms/InsCmsContent.vue'
          )
      },
      {
        path: '/cms/news/:id',
        name: 'cmsNews',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/cms/InsCmsNews.vue'
          )
      },
      {
        path: '/cms/aricle/:id',
        name: 'cmsAricle',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/cms/InsCmsAricle.vue'
          )
      },
      {
        path: '/cms/login/:id',
        name: 'cmsLogin',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/cms/InsCmsLogin.vue'
          )
      },
      {
        path: '/cms/catDetail/:id',
        name: 'catDetail',
        component: () =>
          import(
            /* webpackChunkName: "about" */ './components/business/mobile/cms/InsCatDetail.vue'
          )
      },
      {
        path: '/regnpay/form/:id',
        name: 'regnpay',
        component: () =>
        import(
          /* webpackChunkName: "about" */ './views/mobile/InsRegAndPay.vue'
        )
      },
      {
        path: '/regnpay/result/:id',
        name: 'regnpayResult',
        component: () =>
        import(
          /* webpackChunkName: "about" */ './views/mobile/InsRegNPayResult.vue'
        )
      }
    ]
  }
];

let routers = [
  {
    path: '/',
    component: () => import('./platform/pc/index.vue')
  }
];

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  routers = mRoutes;
} else {
  routers = pRoutes;
}

const router = new Router({
  mode: 'history', // history
  base: process.env.BASE_URL,
  routes: routers
});

export default router;
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;
  if (from.name) {
    Vue.prototype.$ShowLayer();
    setTimeout(() => {
      Vue.prototype.$HiddenLayer();
    }, 3000);
  }
  setTimeout(function () {
    if (to.path === '/account/login') {
      if (storage.get('isLogin')) {
        next({ path: '/home' });
      }
      next();
    } else {
      if (to.meta.requiresAuth && !storage.get('isLogin')) {
        // router.push({ path: '/account/login' });
        next({
          path: '/account/login',
          query: {
            returnurl: to.path
          }
        });
      } else if (to.meta.requiresAuth && storage.get('timeout') && storage.get('timeout') < new Date().getTime()) {
        Vue.prototype.$Login(function () { next(); });
      } else {
        next();
      }
    }
  }, 200);
});
router.beforeEach((to, from, next) => {
  if (to.meta.onlyFrom) {
    if (to.meta.onlyFrom === from.path) next();
    else next(to.meta.onlyFrom);
  } else {
    next();
  }
});
/**
 * 目前蒙层已经不会自动关闭，请在页面适当的时机自行打开。this.$HiddenLayer();
 */
/**
 * 目前所有路由均被拦截加上蒙层，防止页面抖动过大，有需要的小伙伴可以调用：this.$HiddenLayer()，在你觉得合适的时机关闭蒙层（目前我在本文档的159行设置计时器2秒后关闭蒙层）。
 * 如遇ts检测this.$HiddenLayer报找不到改属性或变量，请直接调用Vue.prototype.$HiddenLayer()。其实二者是相同的，但是TS貌似检测不到对象的原型，所以报错。
 * 关于layer配置在./init.ts上，如有需要可以自行修改，或者一起讨论如何修改。
 * */
