







import { Component, Vue } from 'vue-property-decorator';
import Layer from '@/components/service/InsLayer.vue';
import { FrontE } from '@/sdk/common/SysConst';
@Component({
  components: {
    Layer
  }
})
export default class App extends Vue {
  mounted () {
    Vue.prototype.$ShowLayer();
    setTimeout(() => {
      Vue.prototype.$HiddenLayer();
    }, 2000);
  }
  beforeUpdate () {
    // Vue.prototype.$ShowLayer();
    // setTimeout(() => { Vue.prototype.$HiddenLayer(); }, 2000);
    // Api.getData(null, 10);
    if (this.$route.name !== 'home') { return; }
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach((e) => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        document.title = 'Apollo Capital';
      });
    }
  }
  beforeCreate () {
    if (((Vue.prototype.userAgent === 'mobile' && FrontE.mobileBuilding) || (Vue.prototype.userAgent === 'pc' && FrontE.pcBuilding)) && (this.$route.name !== 'building')) {
      this.$router.push('/building');
    }
  }
  // created () {
  //   this.$Api.member.setCurrency(1).then((result) => {
  //     this.$store.dispatch('setCurrency', result.ReturnValue);
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }
}
