import { render, staticRenderFns } from "./InsLayer.vue?vue&type=template&id=c3285d72&scoped=true&"
import script from "./InsLayer.vue?vue&type=script&lang=ts&"
export * from "./InsLayer.vue?vue&type=script&lang=ts&"
import style0 from "./InsLayer.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsLayer.vue?vue&type=style&index=1&id=c3285d72&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3285d72",
  null
  
)

export default component.exports