const AppId = '9ad79f74-00c4-4501-85cc-b4b5fa39a19e';
// const AdminServer = 'http://admin.dev.in-store.hk:84';
const AdminServer = 'https://admin.uat.in-store.hk';
module.exports = {
  // instore app id admin的id
  AppId: AppId, // style5
  // api server url 統一配置Api服務器的url
  ApiServer: 'https://api.uat.in-store.hk',
  // ApiServer: 'http://api.dev.in-store.hk:84',
  // api version for this app 統一配置Api版本號
  ApiVersion: 'V2',
  // api Authorization 身份认证，用于后端识别客户端
  Authorization: 'OWFkNzlmNzQtMDBjNC00NTAxLTg1Y2MtYjRiNWZhMzlhMTllOmY1M2RkNDljLTZhMGUtNGZiNS1hZGM5LThhYjE4OGEzZDQ1Zg==',
  // admin server url, for login management platform 統一配置admin服務器

  AdminServer: AdminServer,
  // url '/admin' auto open admin tab
  AutoOpenAdmin: true,
  // admin login url 統一配置admin登錄地址
  AdminLoginUrl: AdminServer + '/default/ClientLogin/' + AppId,
  // 前端默认配置
  FrontE: {
    defaultLang: 'E',
    defaultCurrency: 1,
    mobileBuilding: false,
    pcBuilding: false,
    cmsSeoList: ['20295', '20296', '20306', '20314', '20301', '20302', '20300', '20307', '20304', '20313', '20303', '20310', '20311'],
    productSeoList: ['Home']
  }
};
